import _export from "../internals/export";
import _getBuiltIn from "../internals/get-built-in";
import _isPure from "../internals/is-pure";
import _promiseNativeConstructor from "../internals/promise-native-constructor";
import _promiseConstructorDetection from "../internals/promise-constructor-detection";
import _promiseResolve from "../internals/promise-resolve";
var $ = _export;
var getBuiltIn = _getBuiltIn;
var IS_PURE = _isPure;
var NativePromiseConstructor = _promiseNativeConstructor;
var FORCED_PROMISE_CONSTRUCTOR = _promiseConstructorDetection.CONSTRUCTOR;
var promiseResolve = _promiseResolve;
var PromiseConstructorWrapper = getBuiltIn("Promise");
var CHECK_WRAPPER = IS_PURE && !FORCED_PROMISE_CONSTRUCTOR;

// `Promise.resolve` method
// https://tc39.es/ecma262/#sec-promise.resolve
$({
  target: "Promise",
  stat: true,
  forced: IS_PURE || FORCED_PROMISE_CONSTRUCTOR
}, {
  resolve: function resolve(x) {
    return promiseResolve(CHECK_WRAPPER && this === PromiseConstructorWrapper ? NativePromiseConstructor : this, x);
  }
});
export default {};