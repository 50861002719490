import _export from "../internals/export";
import _functionCall from "../internals/function-call";
import _aCallable from "../internals/a-callable";
import _newPromiseCapability from "../internals/new-promise-capability";
import _perform from "../internals/perform";
import _iterate from "../internals/iterate";
import _promiseStaticsIncorrectIteration from "../internals/promise-statics-incorrect-iteration";
var $ = _export;
var call = _functionCall;
var aCallable = _aCallable;
var newPromiseCapabilityModule = _newPromiseCapability;
var perform = _perform;
var iterate = _iterate;
var PROMISE_STATICS_INCORRECT_ITERATION = _promiseStaticsIncorrectIteration;

// `Promise.all` method
// https://tc39.es/ecma262/#sec-promise.all
$({
  target: "Promise",
  stat: true,
  forced: PROMISE_STATICS_INCORRECT_ITERATION
}, {
  all: function all(iterable) {
    var C = this;
    var capability = newPromiseCapabilityModule.f(C);
    var resolve = capability.resolve;
    var reject = capability.reject;
    var result = perform(function () {
      var $promiseResolve = aCallable(C.resolve);
      var values = [];
      var counter = 0;
      var remaining = 1;
      iterate(iterable, function (promise) {
        var index = counter++;
        var alreadyCalled = false;
        remaining++;
        call($promiseResolve, C, promise).then(function (value) {
          if (alreadyCalled) return;
          alreadyCalled = true;
          values[index] = value;
          --remaining || resolve(values);
        }, reject);
      });
      --remaining || resolve(values);
    });
    if (result.error) reject(result.value);
    return capability.promise;
  }
});
export default {};