import _export from "../internals/export";
import _newPromiseCapability from "../internals/new-promise-capability";
import _promiseConstructorDetection from "../internals/promise-constructor-detection";
var $ = _export;
var newPromiseCapabilityModule = _newPromiseCapability;
var FORCED_PROMISE_CONSTRUCTOR = _promiseConstructorDetection.CONSTRUCTOR;

// `Promise.reject` method
// https://tc39.es/ecma262/#sec-promise.reject
$({
  target: "Promise",
  stat: true,
  forced: FORCED_PROMISE_CONSTRUCTOR
}, {
  reject: function reject(r) {
    var capability = newPromiseCapabilityModule.f(this);
    var capabilityReject = capability.reject;
    capabilityReject(r);
    return capability.promise;
  }
});
export default {};