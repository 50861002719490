import _export from "../internals/export";
import _isPure from "../internals/is-pure";
import _promiseConstructorDetection from "../internals/promise-constructor-detection";
import _promiseNativeConstructor from "../internals/promise-native-constructor";
import _getBuiltIn from "../internals/get-built-in";
import _isCallable from "../internals/is-callable";
import _defineBuiltIn from "../internals/define-built-in";
var $ = _export;
var IS_PURE = _isPure;
var FORCED_PROMISE_CONSTRUCTOR = _promiseConstructorDetection.CONSTRUCTOR;
var NativePromiseConstructor = _promiseNativeConstructor;
var getBuiltIn = _getBuiltIn;
var isCallable = _isCallable;
var defineBuiltIn = _defineBuiltIn;
var NativePromisePrototype = NativePromiseConstructor && NativePromiseConstructor.prototype;

// `Promise.prototype.catch` method
// https://tc39.es/ecma262/#sec-promise.prototype.catch
$({
  target: "Promise",
  proto: true,
  forced: FORCED_PROMISE_CONSTRUCTOR,
  real: true
}, {
  "catch": function (onRejected) {
    return this.then(undefined, onRejected);
  }
});

// makes sure that native promise-based APIs `Promise#catch` properly works with patched `Promise#then`
if (!IS_PURE && isCallable(NativePromiseConstructor)) {
  var method = getBuiltIn("Promise").prototype["catch"];
  if (NativePromisePrototype["catch"] !== method) {
    defineBuiltIn(NativePromisePrototype, "catch", method, {
      unsafe: true
    });
  }
}
export default {};